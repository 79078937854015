import { Button, Table, Space, notification, Popover, Modal, Input, Row, Col, Form } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { ReactNode, useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { useSearchParams } from "react-router-dom";
import { KilnTypeUIRenderer, genRendererMap } from "../kiln_types/factory";
import { KilnType } from "../kiln_types/common";
import { Can } from "../../permissions";
import { KilnLogSummarySelectable } from "../widgets/datasets/kiln_log_summaries";


/** Component which displays a historyf of calibration events and their utilisation */
function CalibrationHistory(props: any) {

  const renderer: KilnTypeUIRenderer = props.rendererMap.get(props.kiln_type as number)
  const cols : ColumnsType<any> = [
    {
      title: 'Calibration Date/Time',
      dataIndex: 'ts',
      key: 'ts',
      render: (_: any, record: any) => (
        <>{dayjs(record.ts).format('DD-MMM-YYYY hh:mm:ss')}</>
      ),
    },
    {
      title: 'Num Logs Used',
      dataIndex: 'log_count',
      key: 'log_count',
      align: 'center',
    },
    {
      title: 'Calibration Detail',
      dataIndex: 'detail',
      key: 'detail',
      align: 'center',
      render: (_: any, record: any) => {
        if (record.detail && record.detail.length > 0) {
          return (
            <Popover content={renderer.genCalibrationDetailPanel(JSON.parse(record.detail))}>
              <Text type="success">Detail</Text>
            </Popover>);
        }
        else {
          return (<Text>Calibration detail missing. Please contact support.</Text>)
        }
      },
    },
    {
      title: 'Actions',
      align: 'left',
      render: (_: any, record: any) => {
        return (<Button type="dashed" onClick={() => deleteCalibrationSet(record.id, props.refreshCallback)}>Delete</Button>)
      },
    },
  ];

  async function deleteCalibrationSet(id: number, callback: () => void) : Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
    };
    fetch(`/api/kiln/calibration/delete/${id}`, requestOptions)
      .then(response => {
        if (!response.ok) {
          notification.error({
            message: 'Error on calibration delete.',
            description: 'Please contact support to ensure this is investigated.',
            duration: 4,
            placement: "top",
          });
        }
        callback();
      })
      .catch(err => {
        console.log("error on calibration delete request " + err);
        callback();
      });
  }

  return (
    <Space size="small" direction="vertical">
      <Title level={2}>Calibration Sets</Title>
      <Table 
      dataSource={props.sets} 
      rowKey='id'
      columns={cols} 
      scroll={{ x: 1200 }} 
      size="small"
      bordered />
    </Space>
  )
}

/** Component which displays some basic kiln details */
function KilnBasicInfoPanel(props: any) {

  var kilnid = props.kilnid;
  var kiln_type = props.kiln_type;
  var type : ReactNode = (<></>);
  if (kiln_type) {
    const renderer : KilnTypeUIRenderer = props.rendererMap.get(kiln_type as KilnType);

    type = renderer.genKilnTypeTag();
  }
  
  return (
    <>
    <Space size="small" direction="vertical">
      <Title level={2}>Kiln Detail</Title>
      <Space size="small" direction="horizontal">
        <Text>Kiln ID: </Text>
        <Text type="secondary">{kilnid}</Text>
        <Text>Type: </Text>
        {type}
      </Space>
    </Space>
    </>
  )
}

function CalibrationInputForm(props: any) {
  
  // TODO Logic to shift
  function typeSpecificRecordInputCheck(record: any) {
    switch (record.type) {
      case 0:
        return record.biomassRemoved && parseFloat(record.biomassRemoved) > 0 
          && record.biomassLoaded && parseFloat(record.biomassLoaded) > 0
          && record.biomassLessMoisture && parseFloat(record.biomassLessMoisture) > 0;    
      default:
        return true;
    }
  }

  function handleInputChange(e: any) {
    const fieldName : string = e.target.name.slice(0, e.target.name.indexOf('_'));
    const recIndex : number = parseInt(e.target.name.slice(e.target.name.indexOf('_')+1))
    const dupe = [...props.calibrationInputs];
    dupe[recIndex][fieldName] = e.target.value;
    props.setCalibrationInputs(dupe);
    var allValid : boolean = true;
    for (var logInput of dupe) {
      allValid = allValid && typeSpecificRecordInputCheck(logInput)
    }
    props.setCalibrationInputsValid(allValid);
  }

  const genCalibrationInputRow = (val: any, index: number) => {
    const kiln_type: KilnType = val.type as number;
    const renderer: KilnTypeUIRenderer = props.rendererMap.get(kiln_type);
    const analysis_detail: any = JSON.parse(val.detail);

    return (
      <Row>
        <Col span={5}>{dayjs(val.ts).format('DD-MMM-YYYY')}</Col>
        <Col span={4}>
          <Popover content={renderer.genAnalysisDetailPanel(analysis_detail)} title="Analysis Detail">
            <Text type="success">Details</Text>
          </Popover>
        </Col>
        <Col span={5}>
          <Form.Item required={true} tooltip="Biomass loaded as measured in kg.">
            <Input
                name={`biomassLoaded_${index}`}
                type="number"
                min={0}
                max={100000}
                value={val.biomassLoaded}
                onChange={handleInputChange}
              />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item required={true} tooltip="Biomass removed as measured in kg.">
            <Input
                name={`biomassRemoved_${index}`}
                type="number"
                min={0}
                max={100000}
                value={val.biomassRemoved}
                onChange={handleInputChange}
              />
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item required={true} tooltip="Biomass removed as measured in kg after drying.">
            <Input
                name={`biomassLessMoisture_${index}`}
                type="number"
                min={0}
                max={100000}
                value={val.biomassLessMoisture}
                onChange={handleInputChange}
              />
          </Form.Item>
        </Col>
      </Row>
    )
  }

  return (<>
    <Row>
      <Col span={5}><Text>Log Date</Text></Col>
      <Col span={4}><Text>Analysis</Text></Col>
      <Col span={5}><Text>Kg Loaded</Text></Col>
      <Col span={5}><Text>Kg Taken</Text></Col>
      <Col span={5}><Text>Kg Taken (Dry)</Text></Col>
    </Row>
    <Form>
      <div>{props.calibrationInputs.map((v: any, i: number) => genCalibrationInputRow(v, i))}</div>
    </Form>
  </>)
}

function KilnDetail(props : any) {

  const [calibrationLogs, setCalibrationLogs] = useState<any>([]);
  const [kilnRegister, setKilnRegister] = useState<any>({});
  const [selectedRows, setSelectedRows] = useState<Array<number>>([]);
  const [showCalibrationForm, setShowCalibrationForm] = useState<boolean>(false);
  const [calibrationInputs, setCalibrationInputs] = useState<Array<any>>([]);
  const [calibrationInputsValid, setCalibrationInputsValid] = useState<boolean>(false);
  const [calibrationSets, setCalibrationSets] = useState<Array<any>>([]);
  const [trigger, setTrigger] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const kilnTypeRenderMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();

  const input_kilnid : string|null = searchParams.get('id');

  useEffect(() => {
    const executeSearch = async () => {
      await fetch(`/api/kiln/registry/${input_kilnid}`, {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Server error querying kiln details.',
          description: 'Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
      .then(data => {
        if (data != null) {
          setKilnRegister(data.register);
          setCalibrationLogs(data.calibration_logs);
          setCalibrationSets(data.calibration_sets);
        }
        else {
          notification.error({
            message: 'Error querying kiln details.',
            description: 'No details available.',
            duration: 4,
            placement: "top",
          });
        }
      })
      .catch(err => {
        console.log("error " + err);
        notification.error({
          message: 'Error querying kiln details.',
          description: 'Please try again later.',
          duration: 4,
          placement: "top",
        });
      });
      
    }
    executeSearch();
  }, [input_kilnid, trigger])
  
  // a hack to cause a change to the search data, so a re-search is triggered to populate
  // the displayed table.
  function refresh(): void {
    setTrigger(trigger + 1);
  }

  function calibrateWithAll() {
    calibrateWith(calibrationLogs.map((l:any) => l.analysis_id));
  }

  function calibrateWithSelected() {
    calibrateWith(selectedRows);
  }

  function calibrateWith(analysis_ids: Array<number>) {
    const editableRecords : Array<any> = calibrationLogs
      .filter((log: any) => {
        return analysis_ids.includes(parseInt(log.analysis_id));
      })
      .map((logI: any) => {
        return {
          id: logI.analysis_id,
          ts: logI.logts,
          type: logI.type,
          detail: logI.detail,
          // TODO need to work out how to dynamically scale below for other kiln
          biomassLoaded: 0.0,
          biomassRemoved: 0.0,
          biomassLessMoisture: 0.0,
        }
      });
    setCalibrationInputs(editableRecords);
    setShowCalibrationForm(true);
  }

  const handleCalibrationContinue = () => {
    setConfirmLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify({
        kiln_id: kilnRegister.id,
        type: calibrationInputs[0].type,
        calibrationInputs: calibrationInputs
      }),
    };
    fetch("/api/kiln/calibration/create", requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 400) {
          notification.error({
            message: 'Error performing calibration.',
            description: 'Bad request, please check your inputs.',
            duration: 4,
            placement: "top",
          });
          setConfirmLoading(false);
          return null;
        }
        else {
          notification.error({
            message: 'Error performing calibration.',
            description: 'Unknown server error. Try again later or contact support.',
            duration: 4,
            placement: "top",
          });
          setShowCalibrationForm(false);
        }
      })
      .then(data => {
        if (data !== null && data.status === 1) {
          setShowCalibrationForm(false);
          refresh();
        }
        setConfirmLoading(false);
      })
      .catch(err => {
        console.log("Uncaught error! " + err);
        setShowCalibrationForm(false);
      });
  };

  return (
  <div className="wrapper">
    <Space size="small" direction="vertical">
      <KilnBasicInfoPanel 
        kiln_type={kilnRegister.type} 
        rendererMap={kilnTypeRenderMap}
        kilnid={input_kilnid}/>
      <KilnLogSummarySelectable
        title="Logs Eligible for Calibration"
        logs={calibrationLogs}
        showKilnId={false}
        setSelectedRows={setSelectedRows}
        buttonPane={(
          <Can I='create' a='calibration'>
            <Space size="small" direction="horizontal">
              <Button type="primary" onClick={calibrateWithAll}>Calibrate All</Button>
              <Button type="dashed" onClick={calibrateWithSelected} disabled={selectedRows.length === 0}>Calibrate Selected</Button>
            </Space>
          </Can>
        )}/>

      <Can I='read' a='calibration'>
        <CalibrationHistory 
          sets={calibrationSets} 
          kiln_type={kilnRegister.type}
          refreshCallback={refresh} 
          rendererMap={kilnTypeRenderMap} />
      </Can>
      <Modal
        title="Calibration Inputs"
        open={showCalibrationForm}
        onOk={handleCalibrationContinue}
        confirmLoading={confirmLoading}
        okButtonProps={{disabled: !calibrationInputsValid}}
        onCancel={() => setShowCalibrationForm(false)}>
          <CalibrationInputForm  
            calibrationInputs={calibrationInputs} 
            setCalibrationInputs={setCalibrationInputs} 
            setCalibrationInputsValid={setCalibrationInputsValid}
            rendererMap={kilnTypeRenderMap} />
      </Modal>
    </Space>
  </div>
  );
}

export default KilnDetail;