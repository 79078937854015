import { Divider } from "antd";

const RoleBlurb = (props: any) => {
  if (props.jwt != null) {
    switch (props.jwt.role) {
      case 0:
        return (
          <p>As a super-admin, you have access to administer both users and kiln files.</p>
        );
        case 1:
          return (
            <p>You have logged in as a kiln file uploader, which grants no functionality on the website.</p>
          );
          case 2:
        return (
          <p>As user-admin, you have access to create, update, and delete user credentials for the kiln system.</p>
        );
        case 3:
        return (
          <p>As a kiln file admin, you have access to view and download kiln files, as well as view related analysis.</p>
        );
        case 4:
        return (
          <p>As a facility admin, you should have access to manage kilns within your facilities.</p>
        );
      default:
        return (
          <p>Unsupported. If you see this message, please contact an administrator.</p>
        )
    }
  }
  return (
    <></>
  );
}

const ChangeLog = (props: any) => {
  return (
    <>
      <h2>Recent Changes</h2>
      <ul>
        <li>Updated to use proper role library - now have fine-grained permissions control within roles.</li>
        <li>Validation and calibration set support for cgoone kilns. V basic terrafix analysis.</li>
      </ul>
      <Divider/>
      <ul>
        <li>Completely rebuilt backend database schema, introducing the concept of facility, as well as a facility admin role.</li>
        <li>Schema restructure makes things more generic - kiln configuration can take any shape required for whatever kiln varieties we support.</li>
      </ul>
      <Divider/>
      <ul>
        <li>Phase one kiln analysis triggerable from kiln log listing if registry set up.</li>
        <li>Phase one analysis results viewable in the kiln log table.</li>
        <li>Altered default date range for kiln log search to 1 year.</li>
      </ul>
      <Divider/>
      <ul>
        <li>Kiln metadata migration updated to correctly use ISO8601 format for datetimes.</li>
        <li>Basic Kiln Registry page added.</li>
        <li>Backend: Functional kiln log upload endpoint implemented, routing to Cloudflare R2 object store.</li>
        <li>This changelog!!</li>
        <li>Adopted Ant Design components for kiln log table layout.</li>
      </ul>
      <Divider/>      
    </>
  );
}

const Home = (props:any) => {
  
  return (
    <>
      <h1>Kiln Console</h1>
      <RoleBlurb jwt={props.jwt} />
      <ChangeLog />
    </>
  );
};

export default Home;