import Table, { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { Space, Image, Button } from "antd";
import Title from "antd/es/typography/Title";
import { Can } from "../../../permissions";

/** Photos Available for selection */
export function PhotoList(props: any) {
  
    const cols : ColumnsType<any> = [
      {
        title: 'Photo Upload Date',
        dataIndex: 'upload_ts',
        key: 'upload_ts',
        width: 105,
        render: (_: any, record: any) => (
          <>{dayjs(record.upload_ts).format('DD-MMM-YYYY HH:mm')}</>
        ),
      },
      {
        title: 'Kiln',
        dataIndex: 'kiln_id',
        key: 'kiln_id',
        width: 60,
      },
      {
        title: 'Preview',
        dataIndex: 'preview',
        key: 'preview',
        align: 'center',
        width: 150,
        render: (_: any, record: any) => (
          <Image width={150}
            src={`/api/photo/${record.unique_id}/get?token=${sessionStorage.getItem('token')}`}
          />)
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_: any, record: any) => (
          <Can I='delete' a='photo'>
            <Button type="dashed" onClick={() => props.deletePhoto(record.unique_id)}>Delete</Button>
          </Can>
        )
      },
    ];
  
    const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        props.setSelectedRows(selectedRowKeys);
      },
      getCheckboxProps: (record: any) => ({
        disabled: false,
        name: record.name, // TODO remember what this is about
      }),
    };
  
    return (
      <Space direction="vertical" size="small">
        <Space size="small">
          <Title level={3}>{props.title}</Title>
        </Space>
        <Table 
          dataSource={props.photos} 
          rowKey='id'
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={cols} 
          scroll={{ x: 400 }} 
          size="small"
          bordered />
      </Space>
    )
  }