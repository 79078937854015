import { ReactNode, } from "react";
import Text from "antd/es/typography/Text";
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Space, Tag } from "antd";
import { CGoOneUIRenderer } from "./CGoOneUIRenderer";
import { COLOUR_UNDEFINED, KilnType } from "./common";
import { RetortUIRenderer } from "./RetortUIRenderer";
import { TerrafixUIRenderer } from "./TerrafixUIRenderer";

export interface KilnTypeUIRenderer {

  genKilnTypeTag() : ReactNode;

  genKilnValidationDetailPanel(detail: any) : ReactNode;
  genKilnValidationWidget(detail: any, valid: number|null) : ReactNode;

  genAnalysisDetailPanel(json: any) : ReactNode;
  genAnalysisDetailOrErrorIndicator(unparsedDetail: any|null, error: string) : ReactNode;

  genCalibrationDetailPanel(detail: any) : ReactNode;

}

export class UnknownKilnTypeRenderer implements KilnTypeUIRenderer {

  genKilnTypeTag() : ReactNode {
    return (<Tag color='warning' key={-1}>Unknown</Tag>);
  }

  genKilnValidationWidget(detail: any, valid: number|null) : ReactNode {
    return (<QuestionCircleTwoTone twoToneColor={COLOUR_UNDEFINED} />)
  }

  genKilnValidationDetailPanel(detail: any): ReactNode {
    return (<Text>Unknown Kiln Type!</Text>);
  }

  genAnalysisDetailPanel(json: any) : ReactNode {
    return (
      <Space direction="vertical" size="small">
        <Text>Unknown kiln type. Raw content below:</Text>
        <Text>{JSON.stringify(json)}</Text>
      </Space>
    )
  }

  genAnalysisDetailOrErrorIndicator(unparsedDetail: any|null, error: string) : ReactNode {
    return (
      <Space direction="vertical" size="small">
        <Text>Unknown kiln type.</Text>
      </Space>
    )
  }

  genCalibrationDetailPanel(detail: any) : ReactNode {
    return (
      <Space direction="vertical" size="small">
          <Text>Unknown kiln type. Raw content below:</Text>
          <Text>{JSON.stringify(detail)}</Text>
      </Space>
      );
  }

}

export function genRendererForKilnType(type: KilnType): KilnTypeUIRenderer {
  switch (type) {
    case KilnType.CGoOne:
      return new CGoOneUIRenderer();
    case KilnType.Retort:
      return new RetortUIRenderer();
    case KilnType.Terrafix:
      return new TerrafixUIRenderer();      
    default:
      return new UnknownKilnTypeRenderer();
  }
}

export function genRendererMap(): Map<KilnType, KilnTypeUIRenderer> {
  const result: Map<KilnType, KilnTypeUIRenderer> = new Map();
  result.set(KilnType.CGoOne, genRendererForKilnType(KilnType.CGoOne));
  result.set(KilnType.Retort, genRendererForKilnType(KilnType.Retort));
  result.set(KilnType.Terrafix, genRendererForKilnType(KilnType.Terrafix));
  return result;
}