import { Flex, Button, Space, Form, notification, Input } from "antd";
import { useState } from "react";
import PasswordEntry from "../widgets/forms/password";
import Text from "antd/es/typography/Text";


function Profile(props : any) {

  const [userFormContent, setUserFormContent] = useState<any>({
    pass: "",
  });
  const [passValid, setPassValid] = useState<boolean>(false);

  const handleOk = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify({
        pass: userFormContent.pass,
      }),
    };
    fetch(`/api/user/${props.jwt.sub}/update`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 400) {
          notification.error({
            message: 'Error persisting user.',
            description: 'Bad request, please check your inputs.',
            duration: 4,
            placement: "top",
          });
          return null;
        }
        else {
          notification.error({
            message: 'Error persisting user.',
            description: 'Unknown server error. Try again later or contact support.',
            duration: 4,
            placement: "top",
          });
        }
      })
      .then(data => {
        if (data !== null && data.status === 1) {
          notification.info({
            message: 'Saved',
            description: 'Password successfully updated. Please log out and login.',
            duration: 4,
            placement: "top",
          });
        }
      })
      .catch(err => {
        console.log("Uncaught error! " + err);
      });
  }

  function updatePass(pass: string) {
    setUserFormContent({
      ...userFormContent,
      pass: pass,
    });
  }

  return (
  <Flex justify="center" align="flex-start" >
    <Space direction="vertical">
      <Space direction="horizontal" size="middle">
        <h1>User Profile</h1>
        <Text type="secondary">The User Profile allows you to change your password.</Text>
      </Space>
      
        <Form labelCol={{ span: 12 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 1400 }}>
          <Form.Item label='Username' tooltip='Your username cannot be changed'>
            <Input value={props.jwt.sub} disabled />
          </Form.Item>

          <PasswordEntry setOuterPasswordReference={updatePass} signalPassValid={setPassValid} />
          
          <Flex justify="flex-end">
            <Form.Item>
              <Button type="primary" disabled={!passValid} onClick={handleOk}>Update Password</Button>
            </Form.Item>
          </Flex>
        </Form>
      </Space>
  </Flex>
  );
}

export default Profile;