import { CloseSquareTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

export const COLOUR_SUCCESS:    string = "#52c41a";
export const COLOUR_FAILURE:    string = "#ff0000";
export const COLOUR_UNDEFINED:  string = "#b3b3b3";

export enum KilnType {
    CGoOne = 0,
    Retort = 1,
    Terrafix = 2
}

export function genBooleanDisplay(value: boolean): any {
    if (value) {
        return (<CheckCircleTwoTone twoToneColor={COLOUR_SUCCESS} />)
    }
    return (<CloseSquareTwoTone twoToneColor={COLOUR_FAILURE}/>)
}
