import { KilnTypeUIRenderer } from "./factory";
import Text from "antd/es/typography/Text";
import { CloseSquareTwoTone, CheckCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import { Popover, Space, Tag } from "antd";
import { COLOUR_FAILURE, COLOUR_SUCCESS, COLOUR_UNDEFINED, KilnType, genBooleanDisplay } from "./common";
import { ReactNode } from "react";
import { largeValPrettify, msToDuration, numberAsBool } from "../common";

export class CGoOneUIRenderer implements KilnTypeUIRenderer {

    genKilnTypeTag() : ReactNode {
        return (<Tag color='green' key={KilnType.CGoOne}>C-Go-1</Tag>);
    }

    genKilnValidationWidget(detail: any, valid: number|null) : ReactNode {
        if (!detail) {
            return (<QuestionCircleTwoTone twoToneColor={COLOUR_UNDEFINED}/>)
        }
        if (numberAsBool(valid)) {
            return (<CheckCircleTwoTone twoToneColor={COLOUR_SUCCESS} />)
        }
        else {
            return (<CloseSquareTwoTone twoToneColor={COLOUR_FAILURE} />)
        }
    }

    genKilnValidationDetailPanel(detail: any): ReactNode {
        return (
            <Space direction="vertical" size="small">
                <div><Text type="secondary">T1 Max Valid: </Text>{genBooleanDisplay(detail.t1_max_valid)}</div>
                <div><Text type="secondary">T2 Max Valid: </Text>{genBooleanDisplay(detail.t2_max_valid)}</div>
                <div><Text type="secondary">T2 & T1 Max Ratio Valid: </Text>{genBooleanDisplay(detail.maxt2_maxt1_pct_valid)}</div>
                <div><Text type="secondary">Loading Rate Valid: </Text>{genBooleanDisplay(detail.loading_rate_valid)}</div>
                <div><Text type="secondary">Charing Rate Valid: </Text>{genBooleanDisplay(detail.charing_rate_valid)}</div>
                <div><Text type="secondary">Biomass Loaded Valid: </Text>{genBooleanDisplay(detail.biomass_loaded_valid)}</div>
                <div><Text>TODO</Text></div>
          </Space>
        )
    }
      
    genAnalysisDetailPanel(json: any) : ReactNode {
        return (
            <Space direction="vertical" size="small">
                <div><Text type="secondary">Burn Duration: </Text><Text>{msToDuration(json.burn_duration)}</Text></div>
                <div><Text type="secondary">Charing Rate: </Text><Text>{largeValPrettify(json.charing_rate, 2)}kg/m</Text></div>
                <div><Text type="secondary">Loading Rate: </Text><Text>{largeValPrettify(json.loading_rate, 2)}kg/m</Text></div>
                <div><Text type="secondary">Biomass Loaded: </Text><Text>{largeValPrettify(json.biomass_loaded, 2)}kg</Text></div>
                <div><Text type="secondary">Unpyrolyzed Biomass: </Text><Text>{largeValPrettify(json.unpyrolized_biomass, 2)}kg</Text></div>
                <div><Text type="secondary">Biomass Pre-Quench: </Text><Text>{largeValPrettify(json.biochar_prior_to_quench, 2)}kg</Text></div>
            </Space>
            );
    }

    genAnalysisDetailOrErrorIndicator(unparsedDetail: any|null, error: string) : ReactNode {
        if (error && error.length > 0) {
            return (
                <Popover content={<Text>{error}</Text>}>
                  {genBooleanDisplay(false)}
                </Popover>
                ); 
        }
        else if (unparsedDetail && unparsedDetail.length > 3) {
            const vDetail: any = JSON.parse(unparsedDetail);
            return (
              <Popover content={this.genAnalysisDetailPanel(vDetail)}>
                {genBooleanDisplay(true)}
              </Popover>
              );
          }
        return (<></>);
    }
    
    genCalibrationDetailPanel(detail: any) : ReactNode {   
        return (
            <Space direction="vertical" size="small">
                <div><Text type="secondary">Max Temp 1 Std Dev: </Text><Text>{parseFloat(detail.t1_max_stddev).toFixed(4)}</Text></div>
                <div><Text type="secondary">Max Temp 2 Std Dev: </Text><Text>{parseFloat(detail.t2_max_stddev).toFixed(4)}</Text></div>
                <div><Text type="secondary">Temp Percent Ratio Std Dev: </Text><Text>{detail.temp_percent_stddev}</Text></div>
                <div><Text type="secondary">Loading Rate Std Dev: </Text><Text>{parseFloat(detail.loading_rate_stddev).toFixed(4)}</Text></div>
                <div><Text type="secondary">Charing Rate Std Dev: </Text><Text>{parseFloat(detail.charing_rate_stddev).toFixed(4)}</Text></div>
                <div><Text type="secondary">Biomass Loaded Ratio Std Dev: </Text><Text>{parseFloat(detail.biomass_loaded_ratio_stddev).toFixed(4)}</Text></div>
                <div><Text type="secondary">Biomass Removed Ratio Std Dev: </Text><Text>{parseFloat(detail.biomass_removed_ratio_stddev).toFixed(4)}</Text></div>
                <div><Text type="secondary">Biomass Remaining Ratio Std Dev: </Text><Text>{parseFloat(detail.biomass_loaded_ratio_stddev).toFixed(4)}</Text></div>
                <div><Text type="secondary">Biochar Yield Ratio Std Dev: </Text><Text>{parseFloat(detail.biochar_yield_stddev).toFixed(4)}</Text></div>
            </Space>
            );
    }
}