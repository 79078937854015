import { Space } from "antd";


function Capsules(props : any) {

  return (
    <div className="wrapper">
      <Space size="middle">
        <h1>Capsules</h1>
      </Space>  
  </div>
  );
}

export default Capsules;