const SUCCESS = "success";
const ERROR = "error";

/*************
 * VALIDATION Functions
 */
const updateFormValue = (dataUpdate : (a : any) => void, dataRef : any, key: string, val: any, status: string, linkedStatusKey: string|null = null, passStrength: number|null = null) => {
    // iterate through all our entries to evaluate if we're moving to a state where all _status
    // keys are in success state.
    var allValidUpdate = status === SUCCESS;  // if we are setting this to error, then all are not success
    const statusKey: string = key + "_status";

    // if we are setting a val to success, we need to check if any others are in error
    if (allValidUpdate) {
      for (const [iterKey, iterVal] of Object.entries(dataRef)) {
        if (iterKey.endsWith("_status") && iterVal === ERROR) {
          // we've encountered an error status, check if we are setting a _status key to success now, 
          // that this isn't one of them, if it is, we can skip it
          if (statusKey !== iterKey && (linkedStatusKey === null || linkedStatusKey !== iterKey)) {
            allValidUpdate = false;
            break;
          }
        }
      }
    }

    if (linkedStatusKey === null) {
      dataUpdate({
        ...dataRef,
        [key]: val,
        [statusKey]: status,
        "allValid": allValidUpdate,
        "passStrength": passStrength,
      });
    }
    else {
        dataUpdate({
          ...dataRef,
          [key]: val,
          [statusKey]: status,
          [linkedStatusKey]: status,
          "allValid": allValidUpdate,
          "passStrength": passStrength,
        });
    }
  }

export { updateFormValue, ERROR, SUCCESS };