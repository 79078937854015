import { ReactNode, } from "react";
import Text from "antd/es/typography/Text";
import { CloseSquareTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

const KILO : number = 1024;
const SUFFIXES : Array<string> = ["B", "KB", "MB", "GB", "TB", "PB"];

function pad(num:number, size:number) : string {
  var s = "000000000" + num;
  return s.substring(s.length-size);
}

export function numberAsBool(input: number|null) : boolean {
  if (input === null || input === 0) {
    return false;
  }
  return true;
}

export function largeValPrettify(input : number, dp: number): string {
  var focus = Math.floor(input);
  var floating = "";
  if (dp > 0) {
    floating = `.${(input - focus).toFixed(dp).split(".")[1]}`;
  }
  var whole_pretty = "";
  while (focus > 0) {
    var lot = focus % 1000;
    var lotStr : string = focus > 999 ? pad(lot, 3) : "" + lot;
    if (whole_pretty.length > 0) {
      whole_pretty = `${lotStr},${whole_pretty}`;
    }
    else {
      whole_pretty = `${lotStr}`;
    }
    
    focus = Math.floor(focus / 1000)
  }
  return `${whole_pretty}${floating}`;
}

export function msToDuration(millis : number): string {
  const totalSecs = Math.floor(millis / 1000);
  const totalMins = Math.floor(totalSecs / 60);
  const actualSecs = totalSecs % 60;
  const totalHours = Math.floor(totalMins / 60);
  const actualMins = totalMins % 60;
  return `${totalHours.toFixed(0)}h ${actualMins.toFixed(0)}m ${actualSecs}s`;
}

export function sizeDisplay(bytes : number): string {
  
  if (bytes === 0) return "TBD";

  var focus = bytes;
  var counter = 0;
  
  while (focus >= KILO && counter++ < SUFFIXES.length) {
    focus = Math.floor(focus / KILO);
  }

  return `${focus} ${SUFFIXES[counter]}`;
}

export function genGPSPopoverContentDisplay(lat: number, lon: number) {
    return (<Text>{lat.toFixed(4)},{lon.toFixed(4)}</Text>)
}
  
export function genGpsInRangeDisplay(gps_in_range: number) : ReactNode{
    if (gps_in_range) {
        if (gps_in_range === 1) {
        return (<CheckCircleTwoTone twoToneColor="#52c41a" />)
        }
        return (<CloseSquareTwoTone twoToneColor="#ff0000"/>);
    }
    return (<Text>?</Text>);
}
