import { Alert, Form, Progress, ProgressProps } from "antd";
import Password from "antd/es/input/Password";
import { useState } from "react";
import { ERROR, SUCCESS, updateFormValue } from "../../pages/common";
import { passwordStrength } from "check-password-strength";


function PasswordEntry(props: any) {

    const PASS_MISMATCH: string = 'Not matched';
    const PASS_MATCH: string = 'Passwords match!';

    const passStrengthColours: ProgressProps['strokeColor'] = {
        '0%': '#ff0000',
        '33%': '#ff0000',
        '66%': '#005dff',
        '100%': '00ff00',
      };

    const [content, setContent] = useState<any>({
        pass1: "",
        pass1_status: ERROR,
        pass2: "",
        pass2_status: ERROR,
        allValid : false,
    });
    const [passDetails, setPassDetails] = useState<any>({
        passStrength: 0, 
        matched: false, 
    });


    const passRules = (pass: string|null) : number => {
        if (pass === null) {
        return 0;
        }
        return +passwordStrength(pass).id;
    }

    const handlePassChange = (e: any) => {
        const otherPassStatusField = (e.target.name === "pass1" ? "pass2_status" : "pass1_status");
        const match : boolean = e.target.value === (e.target.name === "pass1" ? content.pass2 : content.pass1);
        const passComplexity : number = passRules(e.target.value);
        const passGoodEnough : boolean = passComplexity > 1 && match;
        if (e.target.name === "pass1") {
            setPassDetails({...passDetails, "passStrength" : passComplexity, "matched" : match});
        }
        else {
            // for pass 2 we don't bother updating the complexity, just set the match status
            setPassDetails({...passDetails, "matched" : match});
        }
        updateFormValue(setContent, content, e.target.name, e.target.value, passGoodEnough ? SUCCESS : ERROR, otherPassStatusField);
        
        // finally, if our pass is good enough, call the setter passed to the component so our caller has it
        props.setOuterPasswordReference(e.target.value);
        props.signalPassValid(passGoodEnough);
    }

    return (
        <>
            <Form.Item label='Password' required={true} validateStatus={content.pass1_status} tooltip="Password for this user." >
            <Password 
                name="pass1"
                value={content.pass1} 
                onChange={handlePassChange}/>
            </Form.Item>
            <Form.Item label='Repeat Password' required={true} validateStatus={content.pass2_status} tooltip="Repeated password for this user - must match other password." >
            <Password 
                name="pass2"
                value={content.pass2} 
                onChange={handlePassChange}/>
            </Form.Item>
            <Form.Item label='Password Match' tooltip='Will indicate when passwords match'>
            <Alert 
                type={passDetails.matched ? 'success' : 'error'} 
                message={passDetails.matched ? PASS_MATCH : PASS_MISMATCH} 
                showIcon />
            </Form.Item>
            <Form.Item label='Password Strength' tooltip='More complex passwords are recommended.'>
            <Progress percent={passDetails.passStrength === 3 ? 100 : passDetails.passStrength * 33} strokeColor={passStrengthColours} />
            </Form.Item>
        </>
    );
}

export default PasswordEntry;