import { useState } from "react";
import { Button, Space, Form, Input, notification, Flex } from 'antd';
import { ERROR, SUCCESS } from "./common";
import Password from "antd/es/input/Password";


async function authenticate(credentials : any) : Promise<string | null> {   
      return fetch('/api/auth', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
      .then(data => {
        if (data.status !== 200) {
          return null;
        }
        return data.text();
      })
}

function Login({setToken}: any) {

  const [username, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const token: string|null = await authenticate({
      username,
      password
    });
    if (token != null) {
      setToken(token);
    }
    else {
      notification.error({
        message: 'Authentication Failure',
        description: 'Credentials provided were incorrect. Please try again.',
        duration: 4,
        placement: "top",
      });
    }
  }

  return (
    <>
      <Flex justify="center" align="flex-start" >
        <Space direction="vertical">
          <h1>Login</h1>
          <Form labelCol={{ span: 12 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 1000 }}>
            <Form.Item label='Username' required={true} validateStatus={username.length > 0 ? SUCCESS : ERROR} tooltip="Enter your username." >
              <Input 
                value={username} 
                onChange={e => setUserName(e.target.value)}/>
            </Form.Item>
            <Form.Item label='Password' required={true} validateStatus={password.length > 0 ? SUCCESS : ERROR} tooltip="Your secret password to authenticate." >
              <Password 
                value={password} 
                onChange={e => setPassword(e.target.value)}/>
            </Form.Item>
            <Flex justify="flex-end">
              <Form.Item label="">
                <Button type="primary" disabled={!(username !== null 
                    && username.length > 0 
                    && password !== null 
                    && password.length > 0)} onClick={handleSubmit}>Login</Button>
              </Form.Item>
            </Flex>
          </Form>
        </Space>
      </Flex>
    </>
  );
};

export default Login;