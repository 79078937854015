import { MongoAbility, createMongoAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import React from "react";

type Actions = 'create' | 'update' | 'upload' | 'read' | 'download' | 'delete' | 'approve' | 'reject';
type Subjects = 'facility' | 'user' | 'kiln' | 'kilnlog' | 'analysis' | 'calibration' | 'photo' | 'capsule';

export type AppAbility = MongoAbility<[Actions, Subjects]>;

export const AbilityContext = React.createContext(createMongoAbility());
export const Can = createContextualCan(AbilityContext.Consumer);

/*const ability : PureAbility = buildAbility(jwt.rules as Array<RawRule>);
  
  const buildAbility = (rules: Array<RawRule>): AppAbility => {
  const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);

  

  return build();
};*/