import { useEffect, useState } from "react";
import { Form, Input, Modal, Space, InputNumber, Button, Table, Select, notification, Collapse, Upload, UploadProps, UploadFile } from "antd";
import dayjs from 'dayjs';
import {v4 as uuidv4} from "uuid";
import { ColumnsType } from "antd/es/table";
import { UploadOutlined } from '@ant-design/icons';
import { ERROR, SUCCESS, updateFormValue } from "./common";
import { Can } from "../../permissions";
import type { GetProp } from 'antd';


function FacilitiesTable(props: any) {

  const cols : ColumnsType<any> = [
    {
      title: 'Mnemonic',
      //width: 50,
      dataIndex: 'mnemonic',
      key: 'mnemonic',
      fixed: 'left',
      render: (_: any, record: any) => (
        <a href={`/facility_detail?id=${record.id}`}>{record.mnemonic}</a>
      ),
    },
    {
      title: 'Name',
      //width: 80,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Owner',
      //width: 60,
      dataIndex: 'uid',
      key: 'uid',
      fixed: 'left',
    },
    {
      title: 'GPS Range',
      key: 'gps_range',
      render: (_: any, record: any) => (
        <>[({record.latitude_min}, {record.longitude_min}) - ({record.latitude_max}, {record.longitude_max})]</>
      ),
    },
    {
      title: 'Updated',
      width: 105,
      dataIndex: 'last_updated',
      key: 'last_updated',
      render: (_: any, record: any) => (
        <>{dayjs(record.last_updated).format('DD-MMM-YYYY')}</>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (_:any, record: any) => {
        return (
          <>
            <Can I='update' a='facility'>
              <Button type="dashed" onClick={() => props.editFunction(record)}>Edit</Button>
            </Can>
            <Can I='upload' a='photo'>
              <Button type="dashed" onClick={() => props.showPhotoUpload(record)}>Add Photo</Button>
            </Can>
          </>
        )
      }
    },
  ];

  return (
    <Table 
      dataSource={props.facilities} 
      columns={cols} 
      scroll={{ x: 1200 }} 
      size="small"
      bordered />
  )
}


function Facilities(props : any) {

  const defaultFacility: any = {
    edit: false,
    id: null,
    mnemonic: "",
    mnemonic_status: ERROR,
    name: "",
    owner_id: 1,
    owner_status: SUCCESS,
    latMin: -90.0,
    latMin_status: SUCCESS,
    latMax: 90.0,
    latMax_status: SUCCESS,
    lonMin: -180.0,
    lonMin_status: SUCCESS,
    lonMax: 180.0,
    lonMax_status: SUCCESS,
    config: {
      cgo1: {
        wicf: null,
        moistureContentPostQuench: null,
        relativeMoisture: null,
      }
    },
    allValid: false,
  };

  const defaultPhoto: any = {
    photo: false,
    photo_valid: null,
    kiln_id: "",
    kiln_id_valid: ERROR,
    allValid: false,
  };

  const uidQuery : string = (props.jwt.role === 4 ? props.jwt.sub : '')
  const [facilities, setFacilities] = useState<any[]>([]);
  const [mnemonics, setMnemonics] = useState<any[]>([]);
  const [ownerUids, setOwnerUids] = useState<any[]>([]);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [facilityFormOpen, setFacilityFormOpen] = useState<boolean>(false)
  const [facilityFormContent, setFacilityFormContent] = useState<any>(defaultFacility);

  const [photoModalVisible, setPhotoModalVisible] = useState<boolean>(false)
  const [confirmPhotoLoading, setConfirmPhotoLoading] = useState(false);
  const [photoFormContent, setPhotoFormContent] = useState<any>(defaultPhoto);
  const [photoFiles, setPhotoFiles] = useState<UploadFile[]>([]);
  const [thumb, setThumb] = useState<Blob>();


  // TODO We probably want search limiting of some form here
  const [searchParams, setSearchParams] = useState<any>({
    trigger: 1
  });

  
  useEffect(() => {
    const executeSearch = async () => {
      await fetch('/api/facilities?' + new URLSearchParams({
        uid: uidQuery,
        }), {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
      }).then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Error retrieving facilities.',
          description: 'An unknown error occurred retrieving the facilities. Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
        .then(data => {
          if (data !== null) {
            setFacilities(data.facilities);
            setMnemonics(data.mnemonics);
            setOwnerUids(data.allowedOwners);
          }
        })
        .catch(err => {
          console.log("error " + err);
        });
    }
    executeSearch();
  }, [searchParams, uidQuery])

  // a hack to cause a change to the search data, so a re-search is triggered to populate
  // the displayed table.
  function refresh(): void {
    setSearchParams({
      ...searchParams, 
      trigger: searchParams.trigger + 1});
  }

  // Show facility form with defaults in prep for new facility creation
  const showFacilityFormAsNew = () => {
    setFacilityFormContent(defaultFacility);
    setFacilityFormOpen(true);
  }

  // Show facility form with an existing facility to edit
  const showFacilityFormAsEdit = (record: any) => {
    const config: any = JSON.parse(record.kiln_type_config);
    setFacilityFormContent({
      edit: true,
      id: record.id,
      mnemonic: record.mnemonic,
      mnemonic_status: SUCCESS,
      name: record.name,
      owner_id: record.owner_id,
      owner_status: SUCCESS,
      latMin: record.latitude_min,
      latMin_status: SUCCESS,
      latMax: record.latitude_max,
      latMax_status: SUCCESS,
      lonMin: record.longitude_min,
      lonMin_status: SUCCESS,
      lonMax: record.longitude_max,
      lonMax_status: SUCCESS,
      config: config,
      allValid: true,
    });
    setFacilityFormOpen(true);
  }

  const showPhotoUploadModal = async (record: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
    }
    const kilnSelectList : Array<any> = await fetch(`/api/facility/${record.id}/kilns`, requestOptions)
      .then(response => 
        {
          if (response.ok) {
            return response.json();
          }
        }
      )
      .then(kilnList => {
        return kilnList.kilns;
      })
    
    kilnSelectList.push({
      value: -1, 
      label: "Any/Unknown"
    })
    
    setPhotoFormContent({
      facilityMnemonic: record.mnemonic,
      // todo kiln id or kiln id list choices?
      kilnSelectList: kilnSelectList,
      guid: uuidv4(),
      selected_kiln: -1,
    });
    setPhotoFiles([]);
    setPhotoModalVisible(true);
  }

  const handlePhotoKilnChange = (value:string) => {
    setPhotoFormContent({...photoFormContent, "selected_kiln": value });
  }

  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

  /**
   * Handle Ok - for photo add modal
   */
  const handlePhotoUpload = () => {
    setConfirmPhotoLoading(true);
    
    const formData = new FormData();
    
    formData.append("photo", photoFiles[0] as FileType);
    formData.append("thumb", thumb!);

    
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Related-Facility': photoFormContent.facilityMnemonic,
        'photo-guid': photoFormContent.guid,
      },
      body: formData,
    };

    // if we have a kiln selected, set it in the header

    fetch("/api/photo/upload", requestOptions)
      .then(response => {
        if (response.ok) {
          setConfirmPhotoLoading(false);
          return 1;
        }
        else if (response.status === 400) {
          notification.error({
            message: 'Error uploading photo.',
            description: 'Bad request.',
            duration: 4,
            placement: "top",
          });
          setConfirmPhotoLoading(false);
          return null;
        }
        else {
          notification.error({
            message: 'Error uploading photo.',
            description: 'Server error. Try again later or contact support.',
            duration: 4,
            placement: "top",
          });
          closeModal();
        }
      })
      .then(data => {
        if (data !== null && data === 1) {
          closeModal();
          refresh();
        }
      })
      .catch(err => {
        setConfirmPhotoLoading(false);
        closeModal();
      });
  };

  /**
   * Handle Ok - Form submit for Edit or New Facility
   */
  const handleOk = () => {
    setConfirmLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify({
        id: facilityFormContent.id,
        mnemonic: facilityFormContent.mnemonic,
        name: facilityFormContent.name,
        owner_id: facilityFormContent.owner_id,
        latMin: facilityFormContent.latMin,
        latMax: facilityFormContent.latMax,
        lonMin: facilityFormContent.lonMin,
        lonMax: facilityFormContent.lonMax,
        config: facilityFormContent.config,
      }),
    };
    fetch("/api/facility/" + (facilityFormContent.edit ? "edit" : "add"), requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 400) {
          notification.error({
            message: 'Error persisting facility.',
            description: 'Bad request, please check your inputs.',
            duration: 4,
            placement: "top",
          });
          setConfirmLoading(false);
          return null;
        }
        else {
          notification.error({
            message: 'Error persisting facility.',
            description: 'Unknown server error. Try again later or contact support.',
            duration: 4,
            placement: "top",
          });
          closeModal();
        }
      })
      .then(data => {
        if (data !== null && data.status === 1) {
          closeModal();
          refresh();
        }
      })
      .catch(err => {
        console.log("uncaught error " + err);
        closeModal();
      });
  };

  // Close all modals
  const closeModal = () => {
    setConfirmLoading(false);
    setConfirmPhotoLoading(false);
    setFacilityFormOpen(false);
    setPhotoModalVisible(false);
  };

  /**
   * Handle Cancel - Form hide again
   */
  const handleCancel = () => {
    closeModal();
  };

  

  // coord handling
  const handleLatMinChange = (value: number|null) => {
    updateFormValue(setFacilityFormContent, facilityFormContent, "latMin", value, value !== null && value < facilityFormContent.latMax ? SUCCESS : ERROR, "latMax_status");
  }
  const handleLatMaxChange = (value: number|null) => {
    updateFormValue(setFacilityFormContent, facilityFormContent, "latMax", value, value !== null && value > facilityFormContent.latMin ? SUCCESS : ERROR, "latMin_status");
  }
  const handleLonMinChange = (value: number|null) => {
    updateFormValue(setFacilityFormContent, facilityFormContent, "lonMin", value, value !== null && value < facilityFormContent.lonMax ? SUCCESS : ERROR, "lonMax_status");
  }
  const handleLonMaxChange = (value: number|null) => {
    updateFormValue(setFacilityFormContent, facilityFormContent, "lonMax", value, value !== null && value > facilityFormContent.lonMin ? SUCCESS : ERROR, "lonMin_status");
  }

  const handleMnemonicChange = (e:any) => {
    // check if existing mnemonic set contains this one - error if so
    updateFormValue(setFacilityFormContent, facilityFormContent, "mnemonic", e.target.value, e.target.value === null || e.target.value.length === 0 || mnemonics.includes(e.target.value) ? ERROR : SUCCESS);
  }
  const handleNameChange = (e:any) => {
    updateFormValue(setFacilityFormContent, facilityFormContent, "name", e.target.value, SUCCESS);
  }
  const handleOwnerChange = (value:string) => {
    updateFormValue(setFacilityFormContent, facilityFormContent, "owner_id", value, value === null ? ERROR : SUCCESS);
  }

  // C Go 1 Config item handling functions
  const handleCGoOneWicfChange = (value: number|null) => {
    const oldObj:any =  facilityFormContent.config;
    oldObj.cgo1.wicf = value;

    setFacilityFormContent({
      ...facilityFormContent,
      "config": oldObj,
    });
  }
  const handleCGoOneMoisturePostQuenchChange = (value: number|null) => {
    const oldObj:any =  facilityFormContent.config;
    oldObj.cgo1.moisturePostQuench = value;

    setFacilityFormContent({
      ...facilityFormContent,
      "config": oldObj,
    });
  }
  const handleCGoOneRelativeMoistureChange = (value: number|null) => {
    const oldObj:any =  facilityFormContent.config;
    oldObj.cgo1.relativeMoisture = value;

    setFacilityFormContent({
      ...facilityFormContent,
      "config": oldObj,
    });
  }

  // Accordian config items for config edit panels
  const kilnTypeFormItems=[
    {
      key: "1",
      label: "C-Go-1 Facility Configuration",
      children: <>
          <Form.Item label="Weight Inc. Correction Factor" required={false} tooltip="The Weight Increase Correction Factor (WICF) to be used for analysis of C-Go-1 type kilns in this facility.">
            <InputNumber
                min={0}
                max={100}
                precision={4}
                value={facilityFormContent.config && facilityFormContent.config.cgo1 ? facilityFormContent.config.cgo1.wicf : null}
                onChange={handleCGoOneWicfChange}
              />
          </Form.Item>
          <Form.Item label="Moisture Post Quench" required={false} tooltip="Constant representing the moisture content after the quench has been completed with water. A default will be used if left blank.">
            <InputNumber
                min={0}
                max={100}
                precision={4}
                value={facilityFormContent.config && facilityFormContent.config.cgo1 ? facilityFormContent.config.cgo1.moisturePostQuench : null}
                onChange={handleCGoOneMoisturePostQuenchChange}
              />
          </Form.Item>
          <Form.Item label="Relative Moisture" required={false} tooltip="Relative moisture constant used in analysis. A default will be used if left blank.">
            <InputNumber
                min={0}
                max={100}
                precision={4}
                value={facilityFormContent.config && facilityFormContent.config.cgo1 ? facilityFormContent.config.cgo1.relativeMoisture : null}
                onChange={handleCGoOneRelativeMoistureChange}
              />
          </Form.Item>
        </>,
    },
  ]

  const uploadProps: UploadProps = {
    onRemove: (file: any) => {
      const index = photoFiles.indexOf(file);
      const newFileList = photoFiles.slice();
      newFileList.splice(index, 1);
      setPhotoFiles(newFileList);
    },
    beforeUpload: (file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        var img : HTMLImageElement|null = document.getElementById('funky_image_buffer_woof') as HTMLImageElement;
        if (img === null) {
          const imgElement : HTMLImageElement = document.createElement('img');
          imgElement.setAttribute('id', 'funky_image_buffer_woof');
          img = imgElement;
        }
        var canvas : HTMLCanvasElement | null = document.getElementById('funky_canvas_buffer_woof') as HTMLCanvasElement;
        if (canvas === null) {
          const canvasElement : HTMLCanvasElement = document.createElement('canvas');
          canvasElement.setAttribute('id', 'funky_canvas_buffer_woof');
          canvas = canvasElement;
        }

        if (img !== null && canvas !== null) {
          img.setAttribute('src', reader.result as string);
          img.onload = () => {            
            const scalingFactor : number = Math.max(img!.naturalWidth / 250, img!.naturalHeight / 250);
            canvas!.height = img!.height / scalingFactor;
            canvas!.width = img!.width / scalingFactor;
            canvas!.getContext('2d')?.drawImage(img!, 0, 0, img!.width / scalingFactor, img!.height / scalingFactor);
            
            canvas!.toBlob((result) => {
              setThumb(result as Blob)
            }, 'image/jpeg', 0.8);
          };
        }
      };
      setPhotoFiles([file]);

      return false;
    },
    fileList: photoFiles,
  };

  return (
    <div className="wrapper">
      <Space size="middle">
        <h1>Facilities</h1>
        <Can I='create' a='facility'>
          <Button onClick={() => showFacilityFormAsNew()}>New Facility</Button>
        </Can>
      </Space>
      <Space direction="vertical">
        <FacilitiesTable facilities={facilities} 
          editFunction={(record:any) => showFacilityFormAsEdit(record)}
          showPhotoUpload={(record:any) => showPhotoUploadModal(record)} />
      </Space>
      <Modal title="Facility"
        open={facilityFormOpen}
        onOk={handleOk}
        okButtonProps={{disabled: !facilityFormContent.allValid}}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <Form labelCol={{ span: 12 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 1000 }}>
          <Form.Item label='Mnemonic' required={true} validateStatus={facilityFormContent.mnemonic_status} tooltip="A human memorable id for the kiln" >
            <Input 
              value={facilityFormContent.mnemonic} 
              onChange={handleMnemonicChange}
              disabled={facilityFormContent.edit} />
          </Form.Item>
          <Form.Item label='Name' required={false} tooltip="Facility name" >
            <Input 
              value={facilityFormContent.name} 
              onChange={handleNameChange}/>
          </Form.Item>
          <Form.Item 
            label='Owner' 
            required={true} 
            tooltip="The owner of the facility. Only Facility Admins or Super Admins are shown.">
            <Select
              style={{ width: 120 }}
              onChange={handleOwnerChange}
              options={ownerUids}
              value={facilityFormContent.owner_id}
            />
          </Form.Item>
          <Form.Item 
            label='Latitude Min' 
            required={true} 
            validateStatus={facilityFormContent.latMin_status} 
            tooltip="Southern most permitted latitude for the kilns in this facility.">
            <InputNumber
              min={-90}
              max={90}
              precision={2}
              value={facilityFormContent.latMin}
              onChange={handleLatMinChange}
            />  
          </Form.Item>
          <Form.Item 
            label='Latitude Max' 
            required={true} 
            validateStatus={facilityFormContent.latMax_status}
            tooltip="Northern most permitted latitude for the kilns in this facility.">
            <InputNumber
              min={-90}
              max={90}
              precision={2}
              value={facilityFormContent.latMax}
              onChange={handleLatMaxChange}
            />  
          </Form.Item>
          <Form.Item 
            label='Longitude Min' 
            required={true} 
            validateStatus={facilityFormContent.lonMin_status}
            tooltip="Western most permitted longitude for the kilns in this facility.">
            <InputNumber
              min={-180}
              max={180}
              precision={2}
              value={facilityFormContent.lonMin}
              onChange={handleLonMinChange}
            />  
          </Form.Item>
          <Form.Item 
            label='Longitude Max' 
            required={true}
            validateStatus={facilityFormContent.lonMax_status} 
            tooltip="Eastern most permitted longitude for the kilns in this facility.">
            <InputNumber
              min={-180}
              max={180}
              precision={2}
              value={facilityFormContent.lonMax}
              onChange={handleLonMaxChange}
            />  
          </Form.Item>
          <Collapse accordion items={kilnTypeFormItems} />
        </Form>
        
      </Modal>

      <Modal title="Upload Photo"
        open={photoModalVisible}
        onOk={handlePhotoUpload}
        okButtonProps={{disabled: photoFiles.length === 0}}
        confirmLoading={confirmPhotoLoading}
        onCancel={handleCancel}>
          <Form labelCol={{ span: 12 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 1000 }}>
            <Form.Item label="Facility" required={false} validateStatus={"success"} >
              <Input value={photoFormContent.facilityMnemonic} disabled={true} />
            </Form.Item>
            <Form.Item label="Kiln" required={false} validateStatus={"success"}>
              <Select
                style={{ width: 160 }}
                onChange={handlePhotoKilnChange}
                options={photoFormContent.kilnSelectList}
                value={photoFormContent.selected_kiln}
              />
            </Form.Item>
            <Form.Item label='Image' required={true} validateStatus={photoFormContent.photo_valid} tooltip="Image file to be uploaded" >
              <Upload {...uploadProps} 
                listType="text">
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </Form.Item>
          </Form>
      </Modal>
  </div>
  );
}

export default Facilities;